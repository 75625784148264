import React from "react";
import './Header.web.css';

export default class HomePage extends React.Component {

    constructor(props) {
        super();
        this.navigation = props.navigation;
    }

    render() {

        return (
            <div id="headerContainer">
                <button
                    onClick={() => this.navigation.replace('HomePage')}
                    id="homeButton"
                >
                    <img id="homeButtonImage" src={require('../../assets/images/home.png')} />
                </button>
                <h1 id="headerText">BeNumble</h1>
                <button
                    onClick={() => this.navigation.replace('ResultsPage')}
                    id="statsOpenButton"
                >
                    <img id="statsOpenButtonImage" src={require('../../assets/images/stats.png')} />
                </button>
            </div>

        );
    };
};


