import { StatusBar } from 'expo-status-bar';
import React from "react";
import './GamePage.web.css'
import Numpad from './NumPad/Numpad';
import Header from "../Header/Header";
import { questionSet } from '../../questionSet';

export default class GamePage extends React.Component {

  constructor({ route, navigation }) {
    super();
    this.route = route;
    this.navigation = navigation;
    this.route = route;
    this.numbleChoice = route.params.numbleNumber;
    this.date = new Date();

    this.state = {
      userAnswer: '',
      isPlaying: false,
      showAnswerPage: false,
      time: 0.0,
      buttonsDisabled: false,
      correct: false,
      incorrect: false
    }

    this.stats = {
      numberPlayed: 0,
      bestEasyTime: 200,
      bestMediumTime: 200,
      bestExpertTime: 200,
      easyNumble: { userAnswer: undefined, date: undefined, time: undefined },
      mediumNumble: { userAnswer: undefined, date: undefined, time: undefined },
      expertNumble: { userAnswer: undefined, date: undefined, time: undefined }
    }
  }

  reset = () => {
    this.setState({
      userAnswer: '',
      isPlaying: false,
      showAnswerPage: false,
      time: 0.0,
      buttonsDisabled: false,
      correct: false,
      incorrect: false,
      questionNumber: 0
    })

    for( let i = 0; i < 8; i++ ){
      document.getElementById("questionShownBox" + (i)).style.background = '#fff';
    }
  }

  loadNumble = (number) => {
    this.numbleNumber = this.calculateNumbleNumber();
    this.numbleData = questionSet[this.numbleNumber];
    if (number == 1) {
      this.numble = this.numbleData.easyNumble;
    }
    else if (number == 2) {
      this.numble = this.numbleData.mediumNumble;
    }
    else if (number == 3) {
      this.numble = this.numbleData.expertNumble;
    }

    if (number) {
      this.setState({
        question: this.numble.questions[0],
        answer: this.numble.answer,
      })
    }
  }

  calculateNumbleNumber() {
    const date1 = new Date(2022, 1, 19);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  componentDidMount() {
    this.navigation.addListener(
      'focus',
      this._componentFocused
    );
    
  }

  _componentFocused = (e) => {
    this.numbleChoice = this.route.params.numbleNumber;
    document.addEventListener('keydown', this._handleKeyboardDown);
    this.reset();
    this._loadResults();
    this.loadNumble(this.numbleChoice);
    this.play();
  }


  componentWillUnmount() {
    if (this.state.timerID) {
      window.clearInterval(this.state.timerID);
    }
    document.removeEventListener('keydown', this._handleKeyboardDown);
  }

  _loadResults() {
    let loadedStats = JSON.parse(localStorage.getItem('stats'));
    if (loadedStats != null) {
      this.stats = loadedStats;
    }
  }

  _saveResults(time, userAnswer) {
    this.stats.numberPlayed += 1;

    if (this.numbleChoice == 1) {
      this.stats.easyNumble.userAnswer = userAnswer;
      this.stats.easyNumble.time = time.toFixed(2);
      this.stats.easyNumble.date = this.date;
      if (time < parseFloat(this.stats.bestEasyTime)) {
        this.stats.bestEasyTime = time.toFixed(2);
      }
    }

    if (this.numbleChoice == 2) {
      this.stats.mediumNumble.userAnswer = userAnswer;
      this.stats.mediumNumble.time = time.toFixed(2);
      this.stats.mediumNumble.date = this.date;
      if (time < parseFloat(this.stats.bestMediumTime)) {
        this.stats.bestMediumTime = time.toFixed(2);
      }
    }

    if (this.numbleChoice == 3) {
      this.stats.expertNumble.userAnswer = userAnswer;
      this.stats.expertNumble.time = time.toFixed(2);
      this.stats.expertNumble.date = this.date;
      if (time < parseFloat(this.stats.bestExpertTime)) {
        this.stats.bestExpertTime = time.toFixed(2);
      }
    }

    localStorage.setItem('stats', JSON.stringify(this.stats));
  }

  _handleKeyboardDown = (event) => {

    if (event.code == 'Tab') {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    if (event.code == 'ArrowRight') {
      this.handleNextButtonClick();
    } else if (event.code == 'ArrowLeft') {
      this.handleBackButtonClick();
    }
  }

  play = () => {
    this.setState({ isPlaying: true });
    let questionNumber = 0;
    this.setState({ questionNumber: questionNumber });
    this.setState({ question: this.numble.questions[questionNumber] });

    // Start the timer
    let timerID = setInterval(() => {

      // Your logic here
      this.setState({ time: this.state.time += 0.1 });
      if (this.state.time > 200) {
        window.clearInterval(timerID);
        this.setState({ time: 200 });
      }
    }, 100);

    this.setState({ timerID: timerID });

  };

  handleNextButtonClick = () => {
    if (this.state.isPlaying && this.state.questionNumber != 8) {
      let questionNumber = this.state.questionNumber += 1;
      this.setState({
        questionNumber: questionNumber
      })
      this.setState({ question: this.numble.questions[questionNumber] });
      // Update box
      document.getElementById("questionShownBox" + (questionNumber - 1)).style.background = '#79a76b';

      if (questionNumber === 8) {
        this.setState({ showAnswerPage: true });
      }
    }
  }

  handleBackButtonClick = () => {
    if (this.state.isPlaying && this.state.questionNumber != 0) {
      let questionNumber = this.state.questionNumber -= 1;
      this.setState({
        questionNumber: questionNumber
      })
      this.setState({ question: this.numble.questions[questionNumber] });
      // Update box
      document.getElementById("questionShownBox" + (questionNumber)).style.background = '#fff';
      this.setState({ showAnswerPage: false });
    }
  }

  handleInputEvent = (event) => {
    this.setState({ userAnswer: `${this.state.userAnswer}${event}` });
  }

  handleBackspaceEvent = () => {
    this.setState({ userAnswer: this.state.userAnswer.slice(0, -1) });
  }

  handleConfirmEvent = () => {

    // Disable the numpad
    const userAnswer = this.state.userAnswer;
    const correct = parseInt(userAnswer) == this.state.answer;

    if (correct) {
      window.clearInterval(this.state.timerID);
      this.setState({ correct: true });
      setTimeout(() => {
        this.setState({ correct: false });
        this.navigation.replace('ResultsPage');
      }, 1000);
      this._saveResults(this.state.time, userAnswer);
      this.setState({ buttonsDisabled: true });

    } else {
      this.setState({ incorrect: true });
      setTimeout(() => { this.setState({ incorrect: false }) }, 1000);
    }

  }

  render() {

    let question;
    if (this.state.showAnswerPage) {
      question = <h1 id="answerString"> = {this.state.userAnswer} </h1>
    }
    else {
      question = <h1 id="questionString"> {this.state.question} </h1>


    }
    return (
      <div id="siteWrapper">
        <div id="siteContainer">
          <Header navigation={this.navigation} />
          <div id="controlContainer">
            <div className='questionShownBox' id="questionShownBox0"></div>
            <div className='questionShownBox' id="questionShownBox1"></div>
            <div className='questionShownBox' id="questionShownBox2"></div>
            <div className='questionShownBox' id="questionShownBox3"></div>
            <div className='questionShownBox' id="questionShownBox4"></div>
            <div className='questionShownBox' id="questionShownBox5"></div>
            <div className='questionShownBox' id="questionShownBox6"></div>
            <div className='questionShownBox' id="questionShownBox7"></div>
            <div className='timerContainer'>
              <p className='timerText'>{this.state.time.toFixed(2)}</p>
            </div>
          </div>
          <div id="questionContainer" >
            {this.state.correct ? <p id="tick">✓</p> : null}
            {this.state.incorrect ? <p id="cross">X</p> : null}
            {this.state.isPlaying == true && this.state.questionNumber != 0 ?

              <button id='backButton' onClick={() => this.handleBackButtonClick()}>
                <img src={require('../../assets/images/caret-left.png')} className='buttonImage' />
              </button>
              :
              <div className='buttonSpace' />
            }
            {question}
            {this.state.isPlaying == true && this.state.questionNumber != 8 ?
              <button id='nextButton' onClick={() => this.handleNextButtonClick()}>
                <img src={require('../../assets/images/caret-right.png')} className='buttonImage' />
              </button>
              :
              <div className='buttonSpace' />
            }
          </div>
          <div id="numpadContainer">
            {this.state.showAnswerPage == true &&
              <Numpad
                handleInputEvent={this.handleInputEvent}
                handleBackspaceEvent={this.handleBackspaceEvent}
                handleConfirmEvent={this.handleConfirmEvent}
                disabled={this.state.buttonsDisabled}
              />
            }
          </div>
          <div id="bottomSpacer" />
          <StatusBar style="auto" />
        </div>
      </div>
    );
  };
};


