import React from "react";
import Header from '../Header/Header';
import Stats from './StatsSection';
import './ResultsPage.web.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import html2canvas from 'html2canvas';


export default class ResultsPage extends React.Component {

    constructor({ route, navigation }) {
        super();
        this.today = new Date().setHours(0, 0, 0, 0);
        this.route = route;
        this.navigation = navigation;
        this.state = {
            completedEasy: false,
            completedMedium: false,
            completedExpert: false,
            hours: 0,
            minutes: 0,
            seconds: 0
        }
    }

    componentDidMount() {
        this.navigation.addListener(
            'focus',
            this._componentFocused
        );
    }

    _componentFocused = () => {
        this.countdownTimer();
        let timerID = setInterval(() => { this.countdownTimer(); }, 1000);
        this.setState({ timerID: timerID });

        // Check if todays numbles are complete
        let loadedStats = JSON.parse(localStorage.getItem('stats'));
        if (loadedStats != null) {
            if (new Date(loadedStats.easyNumble.date).setHours(0, 0, 0, 0) == this.today) {
                this.setState({ completedEasy: true });
            }
            if (new Date(loadedStats.mediumNumble.date).setHours(0, 0, 0, 0) == this.today) {
                this.setState({ completedMedium: true });
            }
            if (new Date(loadedStats.expertNumble.date).setHours(0, 0, 0, 0) == this.today) {
                this.setState({ completedExpert: true });
            }
        }
        this.setState({ stats: loadedStats });
    }

    componentWillUnmount() {
        window.clearInterval(this.state.timerID);
    }

    handleCloseButtonClick = () => {
        this.props.handleResultsClose();
    }

    handleShareButtonClick = () => {
        if (navigator.share) {
            alert("Sharing");
            navigator.share({
                title: 'Numble',
                text: `${this.props.stats.thisTime}`
            }).then(() => {
                console.log('Thanks for sharing!');
            })
                .catch(console.error);
        } else {
            alert("Not Sharing");
        }
    }

    handleShareAllButtonClick = () => {
        let resultsContainer = document.getElementById("resultsContainer");
        html2canvas(resultsContainer).then((canvas) => {
            this.shareAllResults(canvas);
        });
    }

    pad(n) {
        return (n < 10 ? '0' : '') + n;
    }

    countdownTimer = () => {

        let today = new Date();
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        // Find the distance between now and the count down date
        let distance = tomorrow.getTime() - today.getTime();

        // Time calculations for days, hours, minutes and seconds
        var hours = this.pad(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        var minutes = this.pad(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        var seconds = this.pad(Math.floor((distance % (1000 * 60)) / 1000));

        this.setState({
            hours: hours,
            minutes: minutes,
            seconds: seconds
        })
    }

    calculateNumbleNumber() {
        const date1 = new Date(2022, 1, 19);
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    shareAllResults = async (canvas) => {
        let numbleNumber = this.calculateNumbleNumber() + 1;
        let text = `Today's BeNumble (${numbleNumber}) Results:`;
        if (navigator.share) {
            const dataUrl = canvas.toDataURL("image/png");
            const blob = await (await fetch(dataUrl)).blob();
            const filesArray = [
                new File(
                [blob],
                'NumbleResults.png',
                {
                    type: blob.type,
                    lastModified: new Date().getTime()
                }
                )
            ];
            navigator.share({
                title: `BeNumble`,
                text: text,
                files: filesArray,
                url: 'https://benumble.com',
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {

            let shareText = `Today's BeNumble (${numbleNumber}) Results:`;
            if (this.state.completedEasy) {
                shareText += `\n Easy: ${this.state.stats.easyNumble.time}`;
            }
            if (this.state.completedMedium) {
                shareText += `\n Medium: ${this.state.stats.mediumNumble.time}`;
            }
            if (this.state.completedExpert) {
                shareText += `\n Expert: ${this.state.stats.expertNumble.time}`;
            }

            if (navigator.clipboard) {
                navigator.clipboard.writeText(shareText).then(function () {
                    toast("Copied results to the clipboard");
                });
            }

            else {

                var dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                dummy.value = shareText;
                dummy.select();
                document.execCommand("copy");
                document.body.removeChild(dummy);
                toast("Copied results to the clipboard");
            }
        }
    }

    shareResults = (difficulty, time) => {
        let numbleNumber = this.calculateNumbleNumber() + 1;
        let text = `BeNumble ${numbleNumber}: ${difficulty} - ${time} s`;
        if (navigator.share) {
            navigator.share({
                title: `BeNumble`,
                text: text,
                url: 'https://benumble.com',
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            if (navigator.clipboard)
            {
                navigator.clipboard.writeText(text).then(function () {
                    toast("Copied results to the clipboard");
                });
            }

            else {
                var dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                dummy.value = text;
                dummy.select();
                document.execCommand("copy");
                document.body.removeChild(dummy);
                toast("Copied results to the clipboard");
            }
        }
    }

    render() {

        let easySection, mediumSection, expertSection;
        // Set the easy complete image
        if (this.state.completedEasy) {
            easySection =
                <div className="resultsSection">
                    <div className="resultsCircleName greenBackground">EASY</div>
                    <Stats numbleChoice={1} time={this.state.stats.easyNumble.time} bestTime={this.state.stats.bestEasyTime} />
                    <div className="resultsShareContainer">
                        <button className="resultsShareButton" onClick={() => this.shareResults('Easy', this.state.stats.easyNumble.time)}>
                            <img src={require('../../assets/images/share.png')} className="resultsShareButtonImage" />
                        </button>
                    </div>
                </div>
        } else {
            easySection =
                <div className="resultsSection">
                    <div className="resultsButtonContainer">
                        <button
                            className="button-3 button zeroMargin"
                            role="button"
                            onClick={() => this.navigation.replace('GamePage', {
                                numbleNumber: 1
                            })}
                        >
                            PLAY EASY
                        </button>
                    </div>
                </div>
        }

        // Set the medium complete image
        if (this.state.completedMedium) {
            mediumSection =
                <div className="resultsSection">
                    <div className="resultsCircleName orangeBackground">MEDIUM</div>
                    <Stats numbleChoice={2} time={this.state.stats.mediumNumble.time} bestTime={this.state.stats.bestMediumTime} />
                    <div className="resultsShareContainer">
                        <button className="resultsShareButton" onClick={() => this.shareResults('Medium', this.state.stats.mediumNumble.time)}>
                            <img src={require('../../assets/images/share.png')} className="resultsShareButtonImage" />
                        </button>
                    </div>
                </div>
        } else {
            mediumSection =
                <div className="resultsSection">
                    <div className="resultsButtonContainer">
                        <button
                            className="button-2 button zeroMargin"
                            role="button"
                            onClick={() => this.navigation.replace('GamePage', {
                                numbleNumber: 2
                            })}
                        >
                            PLAY MEDIUM
                        </button>
                    </div>
                </div>
        }

        // Set the easy complete image
        if (this.state.completedExpert) {
            expertSection =
                <div className="resultsSection">
                    <div className="resultsCircleName redBackground">EXPERT</div>
                    <Stats numbleChoice={3} time={this.state.stats.expertNumble.time} bestTime={this.state.stats.bestExpertTime} />
                    <div className="resultsShareContainer">
                        <button className="resultsShareButton" onClick={() => this.shareResults('Expert', this.state.stats.expertNumble.time)}>
                            <img src={require('../../assets/images/share.png')} className="resultsShareButtonImage" />
                        </button>
                    </div>
                </div>
        } else {
            expertSection =
                <div className="resultsSection">
                    <div className="resultsButtonContainer">
                        <button
                            className="button-1 button zeroMargin"
                            role="button"
                            onClick={() => this.navigation.replace('GamePage', {
                                numbleNumber: 3
                            })}
                        >
                            PLAY EXPERT
                        </button>
                    </div>
                </div>
        }

        return (
            <div id="siteWrapper">
                <div id="siteContainer">
                    <Header navigation={this.navigation} />
                    <ToastContainer />
                    <div id="resultsContainer">
                        <h1>BeNumble Results</h1>
                        {easySection}
                        {mediumSection}
                        {expertSection}

                    </div>
                    <div className="shareAllSection">
                        <div className="resultsButtonContainer">
                            <button
                                className="button-3 button zeroMargin"
                                role="button"
                                onClick={() => this.handleShareAllButtonClick()}
                            >
                                SHARE RESULTS
                            </button>
                        </div>

                    </div>

                    <div id="resultsFooter">
                        <div id="countdown">
                            <h1 className="resultsHeaderText">Tomorrow's BeNumble</h1>
                            <div id="countdownTimer">
                                <p id="countdownText">{this.state.hours} : {this.state.minutes} : {this.state.seconds}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};


