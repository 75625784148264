import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { StatusBar } from 'expo-status-bar';
import HomePage from './components/HomePage/HomePage';

import ResultsPage from './components/ResultsPage/ResultsPage';

import GamePage from './components/GamePage/GamePage';



const Stack = createNativeStackNavigator();

const App = () => {
  return (
    <NavigationContainer style={{ backgroundColor: '#fff' }}>
      <StatusBar style="dark" />
      <Stack.Navigator initialRouteName="HomePage" screenOptions={{ gestureEnabled: false }}>
        <Stack.Screen
          name="HomePage"
          component={HomePage}
          options={{
            title: 'BeNumble',
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="GamePage"
          component={GamePage}
          options={({ navigation }) => ({
            title: 'BeNumble',
            headerShown: false,
          })}
        />

        <Stack.Screen
          name="ResultsPage"
          component={ResultsPage}
          options={({ navigation }) => ({
            title: 'BeNumble',
            headerShown: false,
            // eslint-disable-next-line react/display-name
            headerLeft: (props) => (
              // Ensure navigation back goes to home screen
              null
            ),
            headerRight: () => <ActionBarImage />,
          })}
        />

      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default App;
