/*
 * One-Minute Maths
 * Copyright © 2021 White Rose Maths
 * Numpad.js - Numpad view for the app.
*/

import React from 'react';
import { View, StyleSheet } from 'react-native';
import './Numpad.web.css';
import PropTypes from 'prop-types';

const numbers = ['7', '8', '9', '4', '5', '6', '1', '2', '3'];

const NUMBER = 'NUMEBR';
const DELETE = 'DELETE';
const ENTER = 'ENTER';

export default class Numpad extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;

    this._handleOnScreenButton = this._handleOnScreenButton.bind(this);
  }

  _handleOnScreenButton(event, value, type) {

    if (event.type == 'keydown') {
      return;
    }

    if (event.clientX == 0 && event.clientY == 0) {
      return;
    }

    if (type == NUMBER) {
      this.props.handleInputEvent(value);
    } else if (type == DELETE) {
      this.props.handleBackspaceEvent();
    } else if (type == ENTER) {
      this.props.handleConfirmEvent();
    }

  }

  _handleKeyboardDown = (event) => {

    if (event.code == 'Tab') {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    if (event.code == 'Enter' || event.code == 'NumpadEnter') {
      this.props.handleConfirmEvent();
    } else if (event.code == 'Backspace' || event.code == 'Delete') {
      this.props.handleBackspaceEvent();
    } else {
      let value;
      switch (event.code) {
        case 'Digit1':
        case 'Numpad1':
        {
          value = '1';
          break;
        }
        case 'Digit2':
        case 'Numpad2':
        {
          value = '2';
          break;
        }
        case 'Digit3':
        case 'Numpad3':
        {
          value = '3';
          break;
        }
        case 'Digit4':
        case 'Numpad4':
        {
          value = '4';
          break;
        }
        case 'Digit5':
        case 'Numpad5':
        {
          value = '5';
          break;
        }
        case 'Digit6':
        case 'Numpad6':
        {
          value = '6';
          break;
        }
        case 'Digit7':
        case 'Numpad7':
        {
          value = '7';
          break;
        }
        case 'Digit8':
        case 'Numpad8':
        {
          value = '8';
          break;
        }
        case 'Digit9':
        case 'Numpad9':
        {
          value = '9';
          break;
        }
        case 'Digit0':
        case 'Numpad0':
        {
          value = '0';
          break;
        }
        case 'NumpadSubtract':
        case 'Minus':
        {
          value = '-';
          break;
        }
        default: {
          return;
        }
      }

      this.props.handleInputEvent(value);

    }
  }

  _handleOnButtonFocus() {
    const element = document.getElementById('questions_content');
    if (element) {
      element.focus();
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this._handleKeyboardDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleKeyboardDown);
  }

  render() {
    return (
      <View style={styles.pad}>
        {numbers.map((value, index) => {
          return (
            <button
              key={index}
              className="numberButton"
              onClick={() => this._handleOnScreenButton(event, value, NUMBER)}
              onFocus={this._handleOnButtonFocus()}
              disabled={this.props.disabled}
            >
              <p className="numpad_button_text">{value}</p>
            </button>
          );
        })}
        
        <button
          key="-"
          className="numberButton"
          onClick={() => this._handleOnScreenButton(event, '-', NUMBER)}
          onFocus={this._handleOnButtonFocus()}
          disabled={this.props.disabled}
        >
          <p className="numpad_button_text" >{'\u2212'}</p>
        </button>

        <button
          key="9"
          className="numberButton"
          onClick={() => this._handleOnScreenButton(event, 0, NUMBER)}
          onFocus={this._handleOnButtonFocus()}
          disabled={this.props.disabled}
        >
          <p className="numpad_button_text">0</p>
        </button>

        <button
          key="backspace"
          className="numberButton"
          onClick={() => this._handleOnScreenButton(event, '', DELETE)}
          onFocus={this._handleOnButtonFocus()}
          disabled={this.props.disabled}
        >
          <img
            src={require('../../../assets/images/backspace.png')}
            style={{
              height: '50%',
              aspectRatio: 1,
            }}
          />
        </button>

        <button
          key="confirm"
          className="enterButton"
          onClick={() => this._handleOnScreenButton(event, '', ENTER)}
          onFocus={this._handleOnButtonFocus()}
          disabled={this.props.disabled}
        >
          <p className="enter_button_text" >ENTER</p>
        </button>

      </View>
    );
  }
}

Numpad.propTypes = {
  props: PropTypes.object,
  handleConfirmEvent: PropTypes.func,
  handleInputEvent: PropTypes.func,
  handleBackspaceEvent: PropTypes.func,
  disabled: PropTypes.bool,
};

const styles = StyleSheet.create({
  pad: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    height: '100%',
  },

  button: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '33%',
    height: '25%',
  },

  buttonText: {
    color: '#888',
    fontSize: '80px',
    textAlign: 'center',
    fontFamily: 'WhiteRoseMathsFont',
  },



});
