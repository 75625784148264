import React from "react";
import Header from '../Header/Header';
import './ResultsPage.web.css';


export default class Stats extends React.Component {

    constructor(props) {
        super();
        this.numbleChoice = props.numbleChoice;
        this.time = props.time;
        this.bestTime = props.bestTime;
    }

    render() {
        let text;
        if ( this.numbleChoice == 1) {
            text = <p>Easy</p>
        }
        else if ( this.numbleChoice == 2) {
            text = <p>Medium</p>
        }
        else if ( this.numbleChoice == 3) {
            text = <p>Expert</p>
        }

        return (
            <div className="statsWrapper">
                <p>Todays Time: {this.time} s</p>
                <p>Best Time: {this.bestTime} s</p>
            </div>
        );
    };
};


