import React from "react";
import Header from "../Header/Header";
import './HomePage.web.css';


export default class HomePage extends React.Component {

    constructor({ navigation }) {
        super();
        this.today = new Date().setHours(0, 0, 0, 0);
        this.navigation = navigation;
        this.state = {
            completedEasy: false,
            completedMedium: false,
            completedExpert: false
        }
    }

    componentDidMount() {
        this.navigation.addListener(
            'focus',
            this._componentFocused
        );
    }

    _componentFocused = () => {
        // Check if todays numbles are complete
        let loadedStats = JSON.parse(localStorage.getItem('stats'));
        if (loadedStats != null) {
            if (new Date(loadedStats.easyNumble.date).setHours(0, 0, 0, 0) == this.today) {
                this.setState({ completedEasy: true });
            }
            if (new Date(loadedStats.mediumNumble.date).setHours(0, 0, 0, 0) == this.today) {
                this.setState({ completedMedium: true });
            }
            if (new Date(loadedStats.expertNumble.date).setHours(0, 0, 0, 0) == this.today) {
                this.setState({ completedExpert: true });
            }
        }
    }

    render() {
        let completeEasy, completeMedium, completeExpert, counter;
        // Set the easy complete image
        if (this.state.completedEasy) {
            completeEasy = <img src={require('../../assets/images/complete.png')} className="numbleCompletedImage" />
        } else {
            completeEasy = <img src={require('../../assets/images/incomplete.png')} className="numbleCompletedImage" />
        }

        // Set the medium complete image
        if (this.state.completedMedium) {
            completeMedium = <img src={require('../../assets/images/complete.png')} className="numbleCompletedImage" />
        } else {
            completeMedium = <img src={require('../../assets/images/incomplete.png')} className="numbleCompletedImage" />
        }

        // Set the easy complete image
        if (this.state.completedExpert) {
            completeExpert = <img src={require('../../assets/images/complete.png')} className="numbleCompletedImage" />
        } else {
            completeExpert = <img src={require('../../assets/images/incomplete.png')} className="numbleCompletedImage" />
        }

        // Show countdown?
        if (this.state.completedEasy && this.state.ompletedExpert && this.state.completedMedium) {
            counter = <div id="homeCounterContainer">Counter</div>
        }

        return (
            <div id='siteWrapper'>
                <div id='siteContainer'>
                    <Header navigation={this.navigation} />
                    <div id="homeContainer">
                        <h2 id="aboutString">Daily mental agilty <br></br> maths challenge</h2>
                        <div className="homeButtonContainer">
                            
                            <button
                                className="button-3 button"
                                role="button"
                                onClick={() => this.navigation.navigate('GamePage', {
                                    numbleNumber: 1
                                })}
                                disabled={this.state.completedEasy}
                            >
                                EASY
                            </button>
                            <div className="numbleCompleted">
                                {completeEasy}
                            </div>
                        </div>


                        <div className="homeButtonContainer">
                            <button
                                className="button-2 button"
                                role="button"
                                onClick={() => this.navigation.navigate('GamePage', {
                                    numbleNumber: 2
                                })}
                                disabled={this.state.completedMedium}
                            >
                                MEDIUM
                            </button>
                            <div className="numbleCompleted">
                                {completeMedium}
                            </div>
                        </div>
                        <div className="homeButtonContainer">
                            <button
                                className="button-1 button"
                                role="button"
                                onClick={() => this.navigation.navigate('GamePage', {
                                    numbleNumber: 3
                                })}
                                disabled={this.state.completedExpert}
                            >
                                EXPERT
                            </button>
                            <div className="numbleCompleted">
                                {completeExpert}
                            </div>
                        </div>
                        {counter}

                    </div>
                </div>
            </div>
        );
    };
};


